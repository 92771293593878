<template>
  <div id="app">
    <Header :window-width="windowWidth" style="max-width: 100vw; " />

    <router-view />

    <Footer />
  </div>
</template>

<script>
import Header from '@/components/header/Index.vue'
import Footer from '@/components/footer/Index.vue'
import dayjs from 'dayjs'
import '@fontsource/open-sans'

export default {
  metaInfo: {
    title: 'Home',
    titleTemplate: '%s | Kraker',
    meta: [
      {
        name: `description`,
        content: `Officiële website van de Kraker`,
        vmid: 'og:meta-description'
      }
    ]
  },
  components: {
    Header,
    Footer
  },
  data: function() {
    return {
      windowWidth: 0,
      disabledLocationDateEditorRoutes: [
        'cart',
        'confirmation',
        'reservering-confirmatie',
        'reservation-confirmation'
      ]
    }
  },
  computed: {
    temporaryWarehouses: function() {
      return this.$store.state.temporaryWarehouses
    },
    showLocationDateEditor: function() {
      if (this.disabledLocationDateEditorRoutes.includes(this.$route.name))
        return false

      return true
    },
    toastMessage: function() {
      return this.$store.state.toastMessage
    }
  },
  watch: {
    toastMessage: function(message) {
      if (message !== null) {
        this.$store.commit('sendToast', { toastMessage: null })

        this.$toasted.show(message)
      }
    }
  },
  created: async function() {
    if (
      dayjs(this.$store.state.lastVisitDate)
        .add(3, 'day')
        .isAfter(dayjs())
    ) {
      this.$store.state.lastVisitDate = new Date()
    } else {
      this.$store.state.DateTimeBusinessStart = dayjs().format('DD/MM/YYYY')
      this.$store.state.DateTimeBusinessEnd = dayjs().format('DD/MM/YYYY')
      this.$store.state.DateTimeExpectedStart = dayjs().format('DD/MM/YYYY')
      this.$store.state.DateTimeExpectedEnd = dayjs().format('DD/MM/YYYY')
      this.$store.state.lastVisitDate = new Date()
      this.$store.state.cartCache = {}
    }

    this.windowWidth = window.innerWidth
    this.$store.state.windowWidth = window.innerWidth
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth
      this.$store.state.windowWidth = window.innerWidth
    }
  }
}
</script>

<style lang="scss">
@import './assets/scss/cookiebar';
@import './assets/scss/calendar';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,700;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;700&display=swap');

.item-image-slider a.close {
  color: white !important;
}

.alert-info {
  color: black !important;
  background-color: #f3c500 !important;
  border-color: #f3c500 !important;
  font-weight: 600 !important;
}

button.btn.btn-secondary.disabled {
  background-color: #c1c0c0;
  border-color: #c1c0c0;
  opacity: 1;
}

.vfc-single-input,
.vfc-multiple-input input {
  color: #495057 !important;
}

.fa-spin {
  -webkit-animation: fa-spin 2s linear infinite;
  animation: fa-spin 2s linear infinite;
}

label {
  font-weight: 700;
  font-size: 15px;
}

.fa,
.fab,
.fad,
.fal,
.far,
.fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.fa,
.fab,
.fad,
.fal,
.far,
.fas {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
.fa-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}
.fa-xs {
  font-size: 0.75em;
}
.fa-sm {
  font-size: 0.875em;
}
.fa-1x {
  font-size: 1em;
}
.fa-2x {
  font-size: 2em;
}
.fa-3x {
  font-size: 3em;
}
.fa-4x {
  font-size: 4em;
}
.fa-5x {
  font-size: 5em;
}
.fa-6x {
  font-size: 6em;
}
.fa-7x {
  font-size: 7em;
}
.fa-8x {
  font-size: 8em;
}
.fa-9x {
  font-size: 9em;
}
.fa-10x {
  font-size: 10em;
}
.fa-fw {
  text-align: center;
  width: 1.25em;
}
.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}
.fa-ul > li {
  position: relative;
}
.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}
.fa-border {
  border: 0.08em solid #eee;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}
.fa-pull-left {
  float: left;
}
.fa-pull-right {
  float: right;
}
.fa.fa-pull-left,
.fab.fa-pull-left,
.fal.fa-pull-left,
.far.fa-pull-left,
.fas.fa-pull-left {
  margin-right: 0.3em;
}
.fa.fa-pull-right,
.fab.fa-pull-right,
.fal.fa-pull-right,
.far.fa-pull-right,
.fas.fa-pull-right {
  margin-left: 0.3em;
}
.fa-spin {
  -webkit-animation: fa-spin 2s linear infinite;
  animation: fa-spin 2s linear infinite;
}
.fa-pulse {
  -webkit-animation: fa-spin 1s steps(8) infinite;
  animation: fa-spin 1s steps(8) infinite;
}
@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
.fa-rotate-90 {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=1)';
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.fa-rotate-180 {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=2)';
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.fa-rotate-270 {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=3)';
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}
.fa-flip-horizontal {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)';
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.fa-flip-vertical {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}
.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical,
.fa-flip-vertical {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)';
}
.fa-flip-both,
.fa-flip-horizontal.fa-flip-vertical {
  -webkit-transform: scale(-1);
  transform: scale(-1);
}
:root .fa-flip-both,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270 {
  -webkit-filter: none;
  filter: none;
}
.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
.fab,
.far {
  font-weight: 400;
}

.fa,
.fas {
  font-weight: 900;
}
.svg-inline--fa {
  font-family: inherit;
  text-transform: none;
  text-align: center;
  user-select: none;
  font-weight: 700;
  line-height: 35px;
  text-shadow: none;
  box-sizing: border-box;
  display: inline-block;
  font-size: inherit;
  height: 1em;
  vertical-align: -0.125em;
  width: 1.125em;
  overflow: visible;
}

html {
  font-family: 'Work Sans', sans-serif;
  line-height: 35px;
}

h1 {
  margin-bottom: 1.5rem;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.6rem;
  font-weight: 500 !important;
  line-height: 1.1em;
}

.text-danger a {
  color: #dc3545 !important;
  font-weight: 700;
}

h1::after,
.h1::after,
.block-views-blocknews-and-events-block-1 > h2::after,
.menu--cta > h2::after,
.page-wide-image-wrapper h2::after {
  display: block;
  width: 92px;
  height: 5px;
  margin-top: 5px;

  text-align: center;
  content: '';
  margin-bottom: 40px;
}

.item-planner {
  .nav-tabs .nav-item:first-child {
    a {
      min-width: 247px;
    }
  }
}

a.nav-link.active {
  font-weight: 600;
}

input.form-control.datepicker-input {
  height: 43px;
  border-radius: 0px;
}

.navbar.bg-light {
  background-color: #edf0f1 !important;
}
.navbar-brand {
  a {
    color: black !important;
  }
  img {
    height: 20px;
  }
}
.page-item.active .page-link {
  color: #fff !important;
}
a,
.btn.btn-link {
  color: #f3c500 !important;
}

.btn-secondary {
  color: rgb(40, 40, 40) !important;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
}
.container {
  padding-left: 129.5px;
  padding-right: 129.5px;
}
@media (max-width: 576px) {
  .container {
    max-width: 100vw;
  }
}
header.toast-header {
  border: none;
  display: none;
}

.b-toast .toast {
  opacity: 1;
  background-color: #f3c500 !important;
  color: white;
  font-weight: 900;
}

.no-break {
  white-space: nowrap;
}
@import './assets/scss/bootstrap-variables';
@import './assets/scss/bootstrap/bootstrap';
@import 'node_modules/bootstrap-vue/src/index.scss';

.btn {
  border: none;
  border-radius: 0;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: 0.875rem 1.5rem;
  text-align: center;
  text-decoration: none;
  transition-duration: 250ms;
  transition-property: color, background;
  transition-timing-function: ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.btn-outline-primary {
  color: #f3c500 !important;
  border: #e5eaeb 2px solid;
}
.btn-outline-primary:hover {
  color: white !important;
  border: #f3c500 2px solid;
}

.btn-primary {
  color: black !important;
}

a.bg-primary {
  color: black !important;
}
.btn-light {
  color: #f3c500;
  border: 2px solid white;
  background-color: white;
}

.btn-light:hover {
  color: #3d464d;
  border: 2px solid white;
  background-color: white;
}

.btn-light:active {
  color: #3d464d;
  border: 2px solid white !important;
  background-color: white;
}

.btn-light:focus {
  color: #3d464d;
  border: 2px solid white !important;
  background-color: white;
}

.btn-grey:hover {
  background-color: rgba(255, 255, 255, 0.4);
  color: #2a3336;
}

#app {
  display: grid;
  height: 100vh;
  grid-template-rows: auto 1fr auto;
}
input.form-control {
  padding-left: 12px;
  padding-right: 12px;
}
.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: #f3c500;
  padding-right: add(1.5em, 1.1rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%231fa9ae' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 13px;
}
.custom-select {
  padding: 0.55rem 0.8rem 0.55rem 0.8rem !important;
}

.toasted .primary,
.toasted.toasted-primary {
  background-color: #f3c500 !important;
  border-radius: 30px !important;
  padding: 6px 20px !important;
  margin-bottom: -40px;
}
</style>
