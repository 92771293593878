<template>
  <div class="breadcrumbs my-3 md:my-0">
    <p>
      <span v-for="(breadCrumb, index) in breadCrumbs" :key="breadCrumb.index">
        <router-link :to="`/${$i18n.locale}${breadCrumb.path}`">
          {{ breadCrumb.title }}
        </router-link>

        <span
          v-if="
            index < breadCrumbs.length - 1 ||
              (index < breadCrumbs.length && currentpage)
          "
          class="seperator"
        >
          &nbsp;<icon-chevron-right />
        </span>
      </span>
      <span class="text-black font-bold">{{ currentpage }}</span>
    </p>
  </div>
</template>
<script>
import i18n from '@/locales/index'
import IconChevronRight from '@/components/icons/IconChevronRight'

export default {
  name: 'BreadCrumbs',
  components: {
    IconChevronRight
  },
  props: {
    breadCrumbs: {
      type: Array,
      required: false,
      default: function() {
        return [{ page: 'index', title: 'Shop', path: '/' }]
      }
    },

    currentpage: {
      type: String,
      default: function() {
        return null
      }
    }
  },
  computed: {
    locale: function() {
      return i18n.locale
    }
  }
}
</script>
<style lang="scss" scoped>
.breadcrumbs {
  min-width: 100%;
}
p {
  font-size: 18px;
  font-size: 0.9rem;
}
.seperator {
  ffont-size: 0.6rem;
  line-height: 20px;
  vertical-align: middle;
  padding-left: 2px;
  padding-right: 4px;
  color: #d9d9d9;
}
</style>
