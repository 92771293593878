<template>
  <div class="flex justify-center pt-3 footer-top">
    <div class="pl-3 pr-3">
      <router-link class="text-white" to="/">Home</router-link>
    </div>
    <div class="pl-3 pr-3">
      <a
        target="_blank"
        class="hover:text-primary-light text-white"
        href="https://www.krakertrailers.eu/disclaimer"
        rel="noreferrer"
        >Disclaimer</a
      >
    </div>
    <div class="pl-3 pr-3">
      <a
        target="_blank"
        class="hover:text-primary-light text-white"
        href="https://www.krakertrailers.eu/Kraker/pul/Officieel_reglement.html"
        rel="noreferrer"
        >Reglement</a
      >
    </div>

    <div class="pl-3 pr-3">
      <a
        target="_blank"
        class="text-white"
        href="https://www.krakertrailers.eu/privacybeleid"
        rel="noreferrer"
        >Privacy</a
      >
    </div>
    <div class="pl-3 pr-3">
      <a
        target="_blank"
        class="text-white"
        href="https://www.krakertrailers.eu/contact"
        rel="noreferrer"
        >Contact</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterTop'
}
</script>
<style scoped lang="scss">
a.btn-link {
  color: #fff !important;
}

a:hover {
  color: #f3c500 !important;
}
.footer-top {
  flex-wrap: wrap;
}
</style>
